<template>
  <div v-show="showPage">
    <div style="height: 0; opacity: 0; overflow: hidden; max-width:unset;">
      <b-card
        id="pdfGenerateDiv"
        ref="pdfGenerateDivRef"
        no-body
        class="invoice-preview-card"
      >
        <div
          v-for="pg in pdfTotalPage"
          :key="pg"
        >
          <div
            class="card__inner"
            style="height: 1080px;"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row invoice-spacing mt-0">
                <div>
                  <div class="logo-wrapper">
                    <img
                      style="height:60px;object-fit:contain;"
                      :src="purchaseRequest.company ? (purchaseRequest.company.logo ? purchaseRequest.company.logo : '/nirvana-memorial-garden-logo.png') : '/nirvana-memorial-garden-logo.png'"
                      alt="Logo"
                      crossorigin="anonymous"
                    >
                  </div>
                </div>
                <div
                  style="min-width: fit-content; width: fit-content;"
                  class="px-2"
                >
                  <h4 style="text-align: center; text-transform: uppercase; color: #000000; font-weight: 900; font-size: 12px; line-height: 18px;">
                    Quotation Comparison and Approval Form
                  </h4>
                </div>
                <div class="mt-md-0 mt-2 text-right">
                  <h4
                    class="invoice-title"
                    style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                  >
                    {{ purchaseRequest.caseID }}
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p
                      class="invoice-date"
                      style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                    >
                      <span class="semi-bold">Prepared By:</span> {{ quotation.createdBy ? quotation.createdBy.name : '' }}, {{ quotation.createdBy ? quotation.createdBy.position : '' }}, {{ quotation.createdBy ? (quotation.createdBy.department.length ? quotation.createdBy.department[0].name : '') : '' }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p
                      class="invoice-date"
                      style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                    >
                      <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(quotation.createdAt) }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr
              class="invoice-spacing"
              style="margin-top:25px!important"
            >

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0 main__body"
            >
              <b-row class="invoice-spacing">
                <b-col
                  cols="12"
                  class="p-0 item__description_table"
                >
                  <b-table-simple
                    v-if="chunksOfItemRequests[pg - 1] && chunksOfItemRequests[pg - 1].length"
                    ref="teamsTable"
                    class="position-relative has_padding quote__table borderless__table"
                    responsive
                    caption-top
                  >
                    <b-thead head-variant="dark">
                      <b-tr
                        v-if="quotation.comparedVendors"
                        id="main-table-width"
                        class="group__tag group__tag__white_bg"
                      >
                        <b-th
                          class="empty"
                          colspan="4"
                        >
                          <table class="qcf-table-header">
                            <tbody>
                              <tr>
                                <td>
                                  <span class="text-bold-black">Company</span>
                                </td>
                                <td>{{ purchaseRequest.company ? purchaseRequest.company.name : '' }}</td>
                              </tr>
                              <tr>
                                <td>
                                  <span class="text-bold-black">Justification</span>
                                </td>
                                <td>{{ purchaseRequest.purpose }}</td>
                              </tr>
                              <tr>
                                <td>
                                  <span class="text-bold-black">Department</span>
                                </td>
                                <td>{{ purchaseRequest.department ? purchaseRequest.department.name : '' }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </b-th>
                        <b-th
                          v-for="(vndr, index) in quotation.comparedVendors"
                          :key="index"
                          class="group__tag_info"
                          colspan="3"
                        >
                          <b-badge
                            :variant="vndr.id.status == 'approved' ? 'light-success' : 'light-warning'"
                            class="text-capitalize"
                          >
                            {{ vndr.id.status == 'approved' ? 'Approved Vendor' : 'Not In Vendor List' }}
                          </b-badge>
                          <span class="vn__name">{{ vndr.name }}</span>
                          <span class="vn__gst">{{ vndr.code }}</span>
                        </b-th>
                      </b-tr>

                      <b-tr class="group__tag">
                        <b-th style="border-left: 1px solid #e7edf5 !important;">
                          No
                        </b-th>
                        <b-th>
                          Item Description
                        </b-th>
                        <b-th>
                          Qty
                        </b-th>
                        <b-th style="border-right: 1px solid #e7edf5 !important;">
                          Unit
                        </b-th>
                        <b-th
                          v-for="(vndr, index) in quotation.comparedVendors"
                          :key="index"
                          class="group__tag_info"
                          colspan="3"
                          style="border-right: 1px solid #e7edf5 !important;"
                        >
                          <span class="mr-50 unit_price">u. price($)</span>
                          <span class="mr-50 total_price">TotaL($)</span>
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody
                      v-if="quotation.comparedVendors && chunksOfItemRequests[pg - 1] && chunksOfItemRequests[pg - 1].length"
                      class="white-body-background"
                    >
                      <b-tr
                        v-for="(item, index) in chunksOfItemRequests[pg - 1]"
                        :key="index"
                      >
                        <b-td style="border-right: none !important;">
                          {{ resolveItemCounter(pg, index) }}
                        </b-td>
                        <b-td style="border-right: none !important; max-width: 15rem;">
                          <span class="text-bold-black">{{ item.itemName }}</span>
                          <br>
                          <span class="text-pre">{{ item.itemDescription }}</span>
                        </b-td>
                        <b-td style="border-right: none !important;">
                          {{ item.quantity }}
                        </b-td>
                        <td>
                          {{ item.unit }}
                        </td>
                        <b-td
                          v-for="(vndr, key) in quotation.comparedVendors"
                          :key="key"
                          class="group__tag_info "
                          colspan="3"
                        >
                          <span class="unit_price">{{ item.unitCost[key] }}</span>
                          <span class="total_price">{{ item.unitCost[key] ? numberFormat((item.quantity * item.unitCost[key])) : 0.00 }}</span>
                          <b-badge
                            v-if="item.gst[key] == 'yes'"
                            variant="secondary"
                            style="background:rgba(186, 191, 199, 0.12); color:#000000;"
                          >
                            GST
                          </b-badge>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                    <b-tfoot v-if="(pdfTotalPage == 1) || (pg == (pdfTotalPage - 1) && chunksOfItemRequests[pg - 1] && !chunksOfItemRequests[pg]) || (pg == pdfTotalPage && chunksOfItemRequests[pg - 1])">
                      <tr class="cal_row subtotal_row">
                        <td
                          colspan="4"
                          style="background-color: #F5F8FB !important;"
                        />
                        <b-td
                          v-for="(vndr, key) in quotation.comparedVendors"
                          :key="key"
                          class="group__tag_info "
                          colspan="3"
                          style="padding-left: 8px !important; padding-top: 5px !important; background-color: #F5F8FB !important;"
                        >
                          <span class="mr-50 semi-bold">Subtotal</span> <span class="mr-50">{{ numberFormat(getSubTotal(key)) }}</span><br>
                          <span class="mr-50 semi-bold">GST</span> <span class="mr-50">{{ getGST(key) == '-' ? '-' : (`${getGST(key)} (${quotation.gstList ? (quotation.gstList[key] ? quotation.gstList[key] : quotation.gstPercentage) : quotation.gstPercentage}%)`) }}</span><br>
                          <span class="mr-50 semi-bold">Grand Total</span> <span class="mr-50 semi-bold">{{ numberFormat(getGrandTotal(key)) }}</span>
                        </b-td>
                      </tr>
                      <!-- <tr class="cal_row">
                        <td />
                        <td colspan="3">
                          <span class="semi-bold">Payment terms</span>
                        </td>
                        <b-td
                          v-for="(vndr, key) in quotation.comparedVendors"
                          :key="key"
                          class="group__tag_info"
                          colspan="3"
                        >
                          {{ paymentTerms[key] }}<br>
                          <b-badge
                            v-if="(vndr.id.id + '|//|' + vndr.id.name) == recommendedProvider"
                            variant="secondary"
                          >
                            <feather-icon
                              icon="CheckIcon"
                              class="mr-25"
                            />
                            <span>Recommended</span>
                          </b-badge>
                        </b-td>
                      </tr> -->
                    </b-tfoot>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card-body>

            <div
              v-if="pg == pdfTotalPage"
              style="position: relative; bottom: 0; width: 100%;"
            >
              <b-card-body class="invoice-padding pb-0 footer__area">
                <b-row style="font-size: 11px;">
                  <b-col
                    cols="4"
                    md="4"
                  >
                    <span class="text-bold-black">Recommended External Provider</span>
                    <br>
                    <span>{{ quotation.recommendedProviderName }}</span>
                  </b-col>
                  <b-col
                    cols="8"
                    md="8"
                  >
                    <span class="text-bold-black">Remarks</span>
                    <br>
                    <span class="text-pre">{{ quotation.remarks }}</span>
                  </b-col>
                </b-row>
              </b-card-body>
              <div :style="{ height: gapHeight+'px' }" />
              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0 footer__area">
                <b-row>

                  <!-- Col: Sales Persion -->
                  <b-col
                    v-for="(signatory, index) of quotation.signatories"
                    :key="index"
                    cols="3"
                    md="3"
                    class="mt-md-1 mt-1"
                    order="2"
                    order-md="1"
                  >
                    <b-card-text class="mb-0">
                      <span class="font-weight-bold">{{ index + 1 }}. {{ signatory.type }}</span>
                      <div class="sign-box">
                        <div v-if="signatory.signed == true">
                          <!-- <div v-if="signatory.user && signatory.user.signature">
                            <b-img
                              class="mb-1 mb-sm-0 user__avatar"
                              height="50"
                              :src="signatory.user.signature"
                            />
                          </div> -->
                          <div v-if="signatory.user && signatory.user.name">
                            <span>{{ signatory.user.name }}</span>
                          </div>
                        </div>
                        <div v-else>
                          <p>Pending Signature</p>
                        </div>
                      </div>
                      <p class="ml-75 semi-bold">
                        {{ signatory.user ? signatory.user.name : '' }}
                      </p>
                      <p class="ml-75">
                        {{ signatory.user ? (signatory.user.department.length ? signatory.user.department[0].name : '') : '' }}
                      </p>
                      <p class="ml-75">
                        {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
                      </p>
                    </b-card-text>
                  </b-col>
                </b-row>
              </b-card-body>
            </div>
          </div>
          <!-- <div
            v-if="pg != pdfTotalPage"
            class="html2pdf__page-break"
          /> -->
        </div>
      </b-card>
      <b-card
        no-body
        class="invoice-preview-card"
      >
        <div
          class="card__inner"
          style="height: 1120px;"
        >
          <!-- Header -->
          <b-card-body
            id="main-header"
            class="invoice-padding pb-0"
          >
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="logo-wrapper">
                <img
                  style="height:60px;object-fit:contain;"
                  :src="purchaseRequest.company ? (purchaseRequest.company.logo ? purchaseRequest.company.logo : '/nirvana-memorial-garden-logo.png') : '/nirvana-memorial-garden-logo.png'"
                  alt="Logo"
                  crossorigin="anonymous"
                >
              </div>
              <div
                style="min-width: fit-content; width: fit-content;"
                class="px-2"
              >
                <h4 style="text-align: center; text-transform: uppercase; color: #000000; font-weight: 900; font-size: 12px; line-height: 18px;">
                  Quotation Comparison and Approval Form
                </h4>
              </div>
              <div class="mt-md-0 mt-2 text-right">
                <h4
                  class="invoice-title"
                  style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                >
                  {{ purchaseRequest.caseID }}
                </h4>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Prepared By </span> {{ quotation.createdBy ? quotation.createdBy.name : '' }}, {{ quotation.createdBy ? quotation.createdBy.position : '' }}, {{ quotation.createdBy ? (quotation.createdBy.department.length ? quotation.createdBy.department[0].name : '') : '' }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(new Date()) }}
                  </p>
                </div>
              </div>
            </div>
            <!-- Spacer -->
            <hr
              class="invoice-spacing"
              style="margin-top:25px!important"
            >
          </b-card-body>

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            id="content-div"
            class="invoice-padding pt-0 main__body"
          >
            <b-row class="invoice-spacing">
              <b-col
                cols="12"
                class="p-0 item__description_table"
              >
                <b-table-simple
                  ref="teamsTable"
                  class="position-relative has_padding quote__table borderless__table"
                  responsive
                  caption-top
                >
                  <b-thead head-variant="dark">
                    <b-tr
                      v-if="quotation.comparedVendors"
                      class="group__tag group__tag__white_bg"
                    >
                      <b-th
                        class="empty"
                        colspan="4"
                      >
                        <table class="qcf-table-header">
                          <tbody>
                            <tr>
                              <td>
                                <span class="text-bold-black">Company</span>
                              </td>
                              <td>{{ purchaseRequest.company ? purchaseRequest.company.name : '' }}</td>
                            </tr>
                            <tr>
                              <td>
                                <span class="text-bold-black">Justification</span>
                              </td>
                              <td>{{ purchaseRequest.purpose }}</td>
                            </tr>
                            <tr>
                              <td>
                                <span class="text-bold-black">Department</span>
                              </td>
                              <td>{{ purchaseRequest.department ? purchaseRequest.department.name : '' }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </b-th>
                      <b-th
                        v-for="(vndr, index) in quotation.comparedVendors"
                        :key="index"
                        class="group__tag_info"
                        colspan="3"
                      >
                        <b-badge
                          :variant="vndr.id.status == 'approved' ? 'light-success' : 'light-warning'"
                          class="text-capitalize"
                        >
                          {{ vndr.id.status == 'approved' ? 'Approved Vendor' : 'Not In Vendor List' }}
                        </b-badge>
                        <span class="vn__name">{{ vndr.name }}</span>
                        <span class="vn__gst">{{ vndr.code }}</span>
                      </b-th>
                    </b-tr>

                    <b-tr class="group__tag">
                      <b-th style="border-left: 1px solid #e7edf5 !important;">
                        No
                      </b-th>
                      <b-th>
                        Item Description
                      </b-th>
                      <b-th>
                        Qty
                      </b-th>
                      <b-th style="border-right: 1px solid #e7edf5 !important;">
                        Unit
                      </b-th>
                      <b-th
                        v-for="(vndr, index) in quotation.comparedVendors"
                        :key="index"
                        class="group__tag_info"
                        colspan="3"
                        style="border-right: 1px solid #e7edf5 !important;"
                      >
                        <span class="mr-50 unit_price">u. price($)</span>
                        <span class="mr-50 total_price">TotaL($)</span>
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody
                    v-if="quotation.comparedVendors && chunkItemRequests.length"
                    class="white-body-background"
                  >
                    <b-tr
                      v-for="(item, index) in chunkItemRequests"
                      :key="index"
                    >
                      <b-td style="border-right: none !important;">
                        {{ index + 1 }}
                      </b-td>
                      <b-td style="border-right: none !important; max-width: 15rem;">
                        <span class="text-bold-black">{{ item.itemName }}</span>
                        <br>
                        <span class="text-pre">{{ item.itemDescription }}</span>
                      </b-td>
                      <b-td style="border-right: none !important;">
                        {{ item.quantity }}
                      </b-td>
                      <td>
                        {{ item.unit }}
                      </td>
                      <b-td
                        v-for="(vndr, key) in quotation.comparedVendors"
                        :key="key"
                        class="group__tag_info "
                        colspan="3"
                      >
                        <span class="unit_price">{{ item.unitCost[key] }}</span>
                        <span class="total_price">{{ item.unitCost[key] ? numberFormat((item.quantity * item.unitCost[key])) : 0.00 }}</span>
                        <b-badge
                          v-if="item.gst[key] == 'yes'"
                          variant="secondary"
                          style="background:rgba(186, 191, 199, 0.12); color:#000000;"
                        >
                          GST
                        </b-badge>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                  <b-tfoot id="tableFooter">
                    <tr class="cal_row subtotal_row">
                      <td
                        colspan="4"
                        style="background-color: #F5F8FB !important;"
                      />
                      <b-td
                        v-for="(vndr, key) in quotation.comparedVendors"
                        :key="key"
                        class="group__tag_info "
                        colspan="3"
                        style="padding-left: 8px !important; padding-top: 5px !important; background-color: #F5F8FB !important;"
                      >
                        <span class="mr-50 semi-bold">Subtotal</span> <span class="mr-50">{{ numberFormat(getSubTotal(key)) }}</span><br>
                        <span class="mr-50 semi-bold">GST</span> <span class="mr-50">{{ getGST(key) == '-' ? '-' : (`${getGST(key)} (${quotation.gstList ? (quotation.gstList[key] ? quotation.gstList[key] : quotation.gstPercentage) : quotation.gstPercentage}%)`) }}</span><br>
                        <span class="mr-50 semi-bold">Grand Total</span> <span class="mr-50 semi-bold">{{ numberFormat(getGrandTotal(key)) }}</span>
                      </b-td>
                    </tr>
                    <!-- <tr class="cal_row">
                      <td />
                      <td colspan="3">
                        <span class="semi-bold">Payment terms</span>
                      </td>
                      <b-td
                        v-for="(vndr, key) in quotation.comparedVendors"
                        :key="key"
                        class="group__tag_info"
                        colspan="3"
                      >
                        {{ paymentTerms[key] }}<br>
                        <b-badge
                          v-if="(vndr.id.id + '|//|' + vndr.id.name) == recommendedProvider"
                          variant="secondary"
                        >
                          <feather-icon
                            icon="CheckIcon"
                            class="mr-25"
                          />
                          <span>Recommended</span>
                        </b-badge>
                      </b-td>
                    </tr> -->
                  </b-tfoot>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Total -->
          <b-card-body
            id="footer-div"
            class="invoice-padding pb-0 footer__area"
          >
            <b-row style="font-size: 11px;">
              <b-col
                cols="4"
                md="4"
              >
                <span class="text-bold-black">Recommended External Provider</span>
                <br>
                <span>{{ quotation.recommendedProviderName }}</span>
              </b-col>
              <b-col
                cols="8"
                md="8"
              >
                <span class="text-bold-black">Remarks</span>
                <br>
                <span class="text-pre">{{ quotation.remarks }}</span>
              </b-col>
            </b-row>
            <!-- Spacer -->
            <hr class="invoice-spacing">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                v-for="(signatory, index) of quotation.signatories"
                :key="index"
                cols="3"
                md="3"
                class="mt-md-1 mt-1"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">{{ index + 1 }}. {{ signatory.type }}</span>
                  <div class="sign-box">
                    <div v-if="signatory.signed == true">
                      <!-- <div v-if="signatory.user && signatory.user.signature">
                        <b-img
                          class="mb-1 mb-sm-0 user__avatar"
                          height="50"
                          :src="signatory.user.signature"
                        />
                      </div> -->
                      <div v-if="signatory.user && signatory.user.name">
                        <span>{{ signatory.user.name }}</span>
                      </div>
                    </div>
                    <div v-else>
                      <p>Pending Signature</p>
                    </div>
                  </div>
                  <p class="ml-75 semi-bold">
                    {{ signatory.user ? signatory.user.name : '' }}
                  </p>
                  <p class="ml-75">
                    {{ signatory.user ? (signatory.user.department.length ? signatory.user.department[0].name : '') : '' }}
                  </p>
                  <p class="ml-75">
                    {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
                  </p>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card-body>
        </div>
      </b-card>
    </div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="12"
          >
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h1
                  v-if="user._id == (purchaseRequest.assignedTo ? purchaseRequest.assignedTo._id : '')"
                  class="custom-header-title"
                >
                  Preview Quotation
                </h1>
                <h1
                  v-else
                  class="custom-header-title"
                >
                  Verify Quotation
                </h1>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <section class="invoice-preview-wrapper">
      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <div>
            <b-card
              class="mb-0"
            >
              <b-row class="invoice-preview">
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-left"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="link"
                    :disabled="currentFileIndex == 1"
                    @click="changeFileIndex(currentFileIndex - 1)"
                  >
                    <feather-icon
                      icon="ChevronLeftIcon"
                      class="pt-25"
                      size="18"
                    />
                    <span class="align-middle">Prev</span>
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-center"
                >
                  <b-dropdown
                    id="dropdown-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    split
                  >
                    <template #button-content>
                      <feather-icon
                        icon="FileIcon"
                        size="18"
                        class="align-middle mr-1"
                      />
                      <span class="mr-1">{{ currentFileIndex.toString().padStart(2, "0") }} / {{ purchaseRequest.attachments ? ((purchaseRequest.attachments.length + 1).toString().padStart(2, "0")) : '01' }}: {{ selectedFileName }}</span>
                    </template>
                    <b-dropdown-item @click="changeFileIndex(1)">
                      {{ purchaseRequest.caseID }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-for="(file, key) in purchaseRequest.attachments"
                      :key="key"
                      @click="changeFileIndex(key + 2)"
                    >
                      {{ file ? file.name : '' }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <p
                    v-show="currentFileIndex == 1"
                    class="mt-1"
                  >
                    Quotation Comparison Form (QCF)
                  </p>
                  <p
                    v-show="currentFileIndex != 1"
                    class="mt-1"
                  >
                    {{ purchaseRequest.attachments ? (purchaseRequest.attachments[currentFileIndex - 2] ? purchaseRequest.attachments[currentFileIndex - 2].description : '') : '' }}
                  </p>
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-right"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="link"
                    :disabled="currentFileIndex == (purchaseRequest.attachments ? (purchaseRequest.attachments.length + 1) : 1)"
                    @click="changeFileIndex(currentFileIndex + 1)"
                  >
                    <span class="align-middle">Next</span>
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="pt-25"
                      size="18"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </div>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <div v-show="showAttachment">
              <iframe
                v-show="showPDF"
                id="attachmentpdf"
                width="100%"
                height="1145"
              />
              <b-img
                v-show="!showPDF"
                :src="imageSrc"
                fluid-grow
                alt="Fluid-grow image"
              />
            </div>
            <div v-show="!showAttachment">
              <iframe
                id="docpdf"
                width="100%"
                height="1145"
              />
            </div>
          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
          <b-card v-if="user._id == (purchaseRequest.assignedTo ? purchaseRequest.assignedTo._id : '') && !$route.query.type">
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">Approval Required</span>
                Ready to Submit?
              </h3>
            </template>

            <b-card-text class="mb-0">
              <span v-if="quotation.signatories && quotation.signatories[1]">
                If the information on this Quotation is accurate, e-sign this form and it will be sent to the next assigned signatory ({{ quotation.signatories[1].user.name }}) for signing.
              </span>
              <span v-else>
                If the information on this Quotation is accurate, e-sign this form.
              </span>
            </b-card-text>

            <b-form-checkbox
              v-model="secondAgree"
              value="accepted"
              unchecked-value=""
              class="custom-control-primary"
            >
              I confirm that all information is accurate on this QCF.
            </b-form-checkbox>

            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
              :disabled="purchaseRequest.status == 'cancelled' || secondAgree == '' || ((quotation.signatories ? quotation.signatories[0].signed : '') == true)"
              @click="submitForApproval()"
            >
              <feather-icon
                icon="FeatherIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Sign and Submit QCF</span>
            </b-button>

            <!-- Button: DOwnload -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-primary"
              class="mb-75"
              block
              :to="{ name: 'purchasing-purchase-request-show', params: { id: $route.params.id } }"
            >
              Back to Purchase Request
            </b-button>
          </b-card>
          <b-card v-else>
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">{{ approverHeader }}</span>
                {{ approverTitle }}
              </h3>
            </template>
            <b-card-text
              v-if="$route.query.type && $route.query.type == (quotation.signatories ? (quotation.signatories.length - 1) : 0)"
              class="mb-0"
            >
              <span class="text-bold">You are now reviewing this QCF as the signatory for "{{ approverType }}".</span>
              <br>
              <span>
                If the information on this QCF is accurate, e-sign this form to approve this QCF and start the Purchase Order process.
              </span>
            </b-card-text>
            <b-card-text
              v-else
              class="mb-0"
            >
              <span class="text-bold">You are now reviewing this QCF as the signatory for "{{ approverType }}".</span>
              <br>
              <span>If the information on this QCF is accurate, e-sign this form and it will be sent to the next assigned signatory ({{ nextApproverName }}) for signing.</span>
            </b-card-text>

            <b-form-checkbox
              v-model="secondAgree"
              value="accepted"
              unchecked-value=""
              class="custom-control-primary"
            >
              I confirm that all information is accurate on this QCF.
            </b-form-checkbox>

            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
              :disabled="purchaseRequest.status == 'cancelled' || secondAgree == '' || !canSubmit"
              @click="approveQCF()"
            >
              <feather-icon
                icon="FeatherIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Sign and Approve QCF</span>
            </b-button>

            <!-- Button: DOwnload -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-primary"
              class="mb-75"
              block
              :to="{ name: 'purchasing-purchase-request-show', params: { id: $route.params.id } }"
            >
              Back to Purchase Request
            </b-button>
          </b-card>

          <b-card
            v-if="$route.query.type"
            no-body
          >
            <b-card-header class="primary-card-header">
              <div class="d-flex align-items-center">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3 header-icon"
                >
                  <feather-icon
                    icon="DollarSignIcon"
                    class=""
                    size="20"
                  />
                </b-button>
                <h3 class="align-middle mr-2">
                  Budget
                </h3>
              </div>

              <div
                v-if="expenseItems.length"
                class="d-flex align-items-center"
              >
                <div
                  class="button-prev"
                >
                  <b-button
                    :disabled="currentIndex == 0"
                    variant="none"
                    @click="currentIndex -= 1"
                  >
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="20"
                    />
                  </b-button>
                </div>
                <div
                  slot="pagination"
                  class="swiper-pagination"
                >
                  <span>{{ (currentIndex + 1).toString().padStart(2, 0) }} / {{ expenseItems.length.toString().padStart(2, 0) }}</span>
                </div>
                <div
                  class="button-next"
                >
                  <b-button
                    :disabled="(currentIndex + 1) == expenseItems.length"
                    variant="none"
                    @click="currentIndex += 1"
                  >
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="20"
                    />
                  </b-button>
                </div>
              </div>
            </b-card-header>
            <b-card-body>
              <b-badge
                v-if="purchaseRequest.budgetStatus"
                :variant="`light-${purchaseRequest.budgetStatus == 'allocated' ? 'success' : 'danger'}`"
                class="text-capitalize-first mb-2"
              >
                {{ purchaseRequest.budgetStatus }}
              </b-badge>
              <div
                v-if="expenseItems.length"
              >
                <div class="mb-1">
                  <p class="no-margin text-bold-black">
                    Budgetary Period
                  </p>
                  <span>{{ expenseItems[currentIndex].budgetPeriod.name || '-' }} ({{ dateFormat(expenseItems[currentIndex].budgetPeriod.start) + ' - ' + dateFormat(expenseItems[currentIndex].budgetPeriod.end) }})</span>
                </div>
                <div class="mb-1">
                  <p class="no-margin text-bold-black">
                    Expense Type
                  </p>
                  <span>{{ expenseItems[currentIndex].expenseCategoryType || '-' }}</span>
                </div>
                <div class="mb-1">
                  <p class="no-margin text-bold-black">
                    Cost Centre
                  </p>
                  <span>{{ expenseItems[currentIndex].costCenter.name || '-' }}</span>
                </div>
                <div class="mb-1">
                  <p class="no-margin text-bold-black">
                    Sub-budget (Expense Category)
                  </p>
                  <span>{{ expenseItems[currentIndex].expenseCategory.code || '-' }} - {{ expenseItems[currentIndex].expenseCategory.name }}</span>
                </div>
                <div class="mb-1">
                  <p class="no-margin text-bold-black">
                    Balance
                  </p>
                  <span v-if="canViewThisAction('see-balance', 'BudgetOverview')">{{ expenseItems[currentIndex].subBudget ? (`S$ ${numberFormat(expenseItems[currentIndex].subBudget.remaining)}`) : '-' }}</span>
                  <span v-else>-</span>
                </div>
                <div class="mb-1">
                  <p class="no-margin text-bold-black">
                    Allocated Amount
                  </p>
                  <span>S$ {{ numberFormat(expenseItems[currentIndex].allocated) }}</span>
                </div>
              </div>
              <div
                v-else
                class="row"
              >
                <b-col>
                  <div class="empty_block">
                    <h4>Budget not allocated yet.</h4>
                    <b-button
                      v-if="purchaseRequest.status != 'cancelled' && canViewThisAction('allocate-budget-all-stages', 'BudgetAllocation')"
                      type="button"
                      variant="flat-primary"
                      :disabled="!purchaseRequest.requestType"
                      :to="{ name: 'purchasing-purchase-request-allocate-budget', params: { id: $route.params.id }, query: { type: purchaseRequest.requestType, department: (purchaseRequest.createdBy ? (purchaseRequest.createdBy.department.length ? purchaseRequest.createdBy.department[0]._id : '') : '') } }"
                    >
                      <span class="align-middle">Allocate Budget</span>
                    </b-button>
                  </div>
                </b-col>
              </div>
            </b-card-body>
          </b-card>

          <b-card no-body>
            <b-card-header style="background: none !important;">
              <div class="d-flex align-items-center">
                <feather-icon
                  size="24"
                  class="mr-1"
                  icon="ListIcon"
                />
                <b-card-title class="ml-25">
                  Comments
                </b-card-title>
              </div>
            </b-card-header>

            <!-- timeline -->
            <b-card-body>
              <app-timeline>
                <app-timeline-item
                  v-for="(opt, key) in prComments"
                  :key="key"
                  variant="success"
                >
                  <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                    <h6 style="max-width: 100%;">
                      {{ opt.createdBy.name }}
                    </h6>
                  </div>
                  <small class="timeline-item-time text-nowrap text-muted">{{ humanReadableFormat(opt.createdAt) }}</small>
                  <p>{{ opt.comment }}</p>
                </app-timeline-item>
              </app-timeline>
              <!--/ timeline -->
              <b-card-text class="mb-0">
                <b-form-textarea
                  id="h-purchaseRequest-comment"
                  v-model="extraComment"
                  trim
                  rows="3"
                  placeholder="Type to leave a comment. Comments are visible to all users who have access to this Purchase Request."
                />
              </b-card-text>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-75 ml-auto"
                :disabled="extraComment == ''"
                @click="addComment()"
              >
                <span class="align-middle">Add Comment</span>
              </b-button>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BImg, BFormTextarea, BCardText, VBTooltip, BTableSimple, BTbody, BThead, BTfoot,
  BTr, BTh, BTd, BFormCheckbox, BCardBody, BDropdown, BDropdownItem, BCardHeader, BCardTitle, BBadge,
} from 'bootstrap-vue'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import html2pdf from 'html2pdf.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BImg,
    BFormTextarea,
    BCardText,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTh,
    BTd,
    BFormCheckbox,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BCardHeader,
    BCardTitle,
    BBadge,

    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  // props: {
  //   purchaseRequest: {
  //     type: Object,
  //     required: true,
  //   },
  //   quotation: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      showPage: false,
      canSubmit: false,
      purchaseRequest: {},
      quotation: {},
      quotationItems: [],
      tabType: '',
      purchaseRequestSignatories: [],
      purchaseRequestItems: [],
      purchaseRequestID: '',
      stringID: '',
      comment: '',
      extraComment: '',
      approverType: '',
      approverHeader: '',
      approverTitle: '',
      nextApproverName: '',
      commentValidation: false,
      commentError: '',
      status: 'confirmed',
      statusValidation: false,
      selectedFileName: '',
      currentFileIndex: 1,
      chunkItemRequests: [],
      chunksOfItemRequests: [],
      prComments: [],
      pdfTotalPage: 0,
      elHeight: 0,
      gapHeight: 0,
      secondAgree: '',
      pdfDataJS: '',
      imageSrc: '',
      showPDF: true,
      showAttachment: false,
      showFirst: true,
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Draft', code: 'draft' },
        { title: 'Pending Edit', code: 'pending edit' },
        { title: 'Pending Approval', code: 'pending approval' },
        { title: 'Verifying', code: 'verifying' },
        { title: 'Verified', code: 'verified' },
        { title: 'Cancelled', code: 'cancelled' },
      ],
      description: '',
      attachmentKey: '',
      required,
      budgetExpense: {},
      expenseItems: [],
      currentIndex: 0,
      lqas: [
        '1. Prepared by',
        '2. Requested by',
        '3. Verified by',
        '4. Recommended by',
        '5. Agreed by',
        '6. Certified by',
        '7. Confirmed by',
        '8. Supported by',
        '9. Validated by',
        '10. Approved by',
      ],
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  mounted() {
    this.$http.get(`purchase/quotations/purchase-request/${this.$route.params.id}/detail`)
      .then(response => {
        this.purchaseRequest = response.data.purchaseRequest || {}
        this.budgetExpense = response.data.budgetExpense
        this.expenseItems = response.data.expenseItems
        this.quotation = response.data.quotation || {}
        this.quotationItems = response.data.quotationItems || []
        this.selectedFileName = this.purchaseRequest.caseID
        this.prComments = response.data.comments
        if (this.$route.query.type) {
          let { type } = this.$route.query
          // eslint-disable-next-line radix
          type = parseInt(type)

          if (this.quotation.signatories[type].signed === true || this.resolveSignValidation(type) || this.quotation.signatories[type].user._id !== this.user._id) {
            this.$router.push({ name: 'page-not-found' })
          }
          if (type === (this.quotation.signatories.length - 1)) {
            this.approverType = this.quotation.signatories[type].type
            this.approverHeader = 'Signature Required'
            this.approverTitle = 'Approve This QCF?'
            this.nextApproverName = ''
          } else {
            this.approverType = this.quotation.signatories[type].type
            this.approverHeader = 'Signature Required'
            this.approverTitle = 'Approve This QCF?'
            this.nextApproverName = this.quotation.signatories[type + 1].user.name
          }
          this.canSubmit = true
        }
        this.showPage = true
        this.enablePreview()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    resolveSignValidation(key) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < key; i++) {
        if (this.quotation.signatories[i].signed === false) return true
      }
      return false
    },
    changeFileIndex(key) {
      this.currentFileIndex = key
      if (key === 1) {
        this.selectedFileName = this.purchaseRequest.caseID
        // eslint-disable-next-line func-names
        // pdfViewerFrame.onload = () => {
        //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.pdfDataJS)
        // }
        // this.showFirst = false
        // const pdfViewerFrame = document.getElementById('docpdf2')
        // pdfViewerFrame.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.pdfDataJS}`)
        this.showAttachment = false
      } else {
        this.selectedFileName = this.purchaseRequest.attachments[key - 2].name
        if (this.purchaseRequest.attachments[key - 2].type.includes('pdf')) {
          // eslint-disable-next-line func-names
          // pdfViewerFrame.onload = () => {
          //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.purchaseRequest.attachments[key - 2].data)
          // }
          const pdfViewerFrame2 = document.getElementById('attachmentpdf')
          pdfViewerFrame2.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.purchaseRequest.attachments[key - 2].data}`)
          this.showPDF = true
        } else {
          this.imageSrc = this.purchaseRequest.attachments[key - 2].data
          this.showPDF = false
        }
        this.showAttachment = true
      }
    },
    resolveItemCounter(page, index) {
      let count = 0
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < (page - 1); i++) {
        count += this.chunksOfItemRequests[i].length
      }

      count += index + 1

      return count
    },
    base64ToUint8Array(base64) {
      const raw = atob(base64)
      const uint8Array = new Uint8Array(raw.length)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i)
      }
      return uint8Array
    },
    async enablePreview() {
      store.commit('appConfig/UPDATE_LOADER', true)
      const vm = this
      await this.calculateTotalPage()

      // start of pdf using html2PDF package
      const element = document.getElementById('pdfGenerateDiv')
      const width = document.getElementById('main-table-width').offsetWidth * 0.2645833333 + 50
      const opt = {
        margin: 1,
        filename: 'myfile.pdf',
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'], after: '.page-section' },
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          useCORS: true,
          scale: 2,
          dpi: 192,
          // windowWidth: 1024,
          letterRendering: true,
        },
        jsPDF: { unit: 'mm', format: [width, 210], orientation: 'l' },
      }

      html2pdf().set(opt).from(element).toPdf()
        .get('pdf')
        .then(pdf => {
          vm.pdfData = pdf.output('datauristring')
          const base64Raw = vm.pdfData.split(',')[1]
          const pdfDataJS2 = vm.base64ToUint8Array(base64Raw)
          this.pdfDataJS = pdfDataJS2
          const pdfViewerFrame = document.getElementById('docpdf')
          // eslint-disable-next-line func-names
          pdfViewerFrame.onload = () => {
            pdfViewerFrame.contentWindow.PDFViewerApplication.open(pdfDataJS2)
          }
          pdfViewerFrame.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          store.commit('appConfig/UPDATE_LOADER', false)
        })
      // end of pdf using html2PDF package
      // this.pdfData = data
      // this.QCFPreview = true
    },
    async calculateTotalPage() {
      await this.$nextTick()
      this.chunksOfItemRequests = []
      this.chunkItemRequests = []
      this.pdfTotalPage = 0
      const logoHeight = document.getElementById('main-header').offsetHeight
      const tableFooterHeight = document.getElementById('tableFooter').offsetHeight
      const footerHeight = document.getElementById('footer-div').offsetHeight
      let contentHeight = 0
      let totalItems = this.quotationItems.length
      // let i = 0
      let sliceStart = 0
      let sliceEnd = 10
      if (totalItems > 10) {
        sliceEnd = 14
      }
      while (totalItems > 0) {
        contentHeight = 1000 - (logoHeight + tableFooterHeight + footerHeight)
        // console.log(contentHeight)
        this.chunkItemRequests = this.quotationItems.slice(sliceStart, sliceEnd)
        // this.$nextTick(() => {
        //   console.log(document.getElementById('content-div').offsetHeight)
        // })
        // eslint-disable-next-line no-await-in-loop
        await this.$nextTick()
        let tableHeight = document.getElementById('content-div').offsetHeight - tableFooterHeight
        // console.log(tableHeight)
        if (tableHeight > contentHeight) {
          contentHeight += footerHeight
          // contentHeight += tableFooterHeight
        }
        // // console.log(tableHeight)
        while (tableHeight > contentHeight) {
          sliceEnd -= 1
          // console.log(contentHeight, tableHeight)
          this.chunkItemRequests = this.quotationItems.slice(sliceStart, sliceEnd)
          // console.log(sliceStart, sliceEnd)
          // eslint-disable-next-line no-await-in-loop
          await this.$nextTick()
          tableHeight = document.getElementById('content-div').offsetHeight - tableFooterHeight
          // console.log(tableHeight, contentHeight)
        }
        // console.log(tableHeight)
        // console.log(sliceEnd - sliceStart)
        // console.log(this.chunkItemRequests.length)
        this.chunksOfItemRequests.push(this.chunkItemRequests)
        this.pdfTotalPage += 1
        // i += 1
        sliceStart = sliceEnd
        sliceEnd += 17
        totalItems -= this.chunkItemRequests.length
      }

      // if (this.pdfTotalPage === 1) {
      //   contentHeight = 940 - (logoHeight + tableFooterHeight + footerHeight)
      // } else {
      //   contentHeight = 940 - (logoHeight + footerHeight)
      // }
      // const finalHeight = document.getElementById('content-div').offsetHeight
      // if (finalHeight > contentHeight) {
      //   this.pdfTotalPage += 1
      // }
      // let remainingHeight = 0
      // const dHeight = 1000
      // // if (this.isMobile()) {
      // //   dHeight = 1120
      // // }
      // let finalDataHeight = 0
      // if (this.pdfTotalPage === 1) {
      //   finalDataHeight = document.getElementById('content-div').offsetHeight
      // } else {
      //   finalDataHeight = document.getElementById('content-div').offsetHeight - tableFooterHeight
      // }
      // this.gapHeight = 0
      // remainingHeight = dHeight - (logoHeight + footerHeight + finalDataHeight)
      // if (remainingHeight > 0) {
      //   this.gapHeight = remainingHeight
      // }
      // if (finalDataHeight >= (dHeight - (logoHeight + footerHeight))) {
      //   this.pdfTotalPage += 1
      //   this.gapHeight = dHeight - (logoHeight + footerHeight)
      // }
    },
    addComment() {
      this.$http
        .post('purchase/purchase-request/save-comment', { comment: this.extraComment, stage: 'in-quotation', purchaseRequest: this.$route.params.id })
        .then(response => {
          this.extraComment = ''
          this.prComments = response.data.comments
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.purchaseRequest.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    getSubTotal(key) {
      const vendor = this.quotation.comparedVendors[key]
      let total = 0
      if (vendor.code === 'GST Inclusive') {
        this.quotationItems.forEach(item => {
          total += item.unitCost[key] ? item.quantity * item.unitCost[key] : 0
        })
        total -= this.getGST(key)
      } else {
        this.quotationItems.forEach(item => {
          total += item.unitCost[key] ? item.quantity * item.unitCost[key] : 0
        })
      }

      const discount = this.quotation.discountList[key] ? this.quotation.discountList[key] : 0
      const dCost = this.quotation.deliveryCost[key] ? this.quotation.deliveryCost[key] : 0

      total = total - discount + parseFloat(dCost)
      return Number.isNaN(total) ? '0.00' : total.toFixed(2)
    },
    getGST(key) {
      // eslint-disable-next-line no-nested-ternary
      const gstPercentageVal = this.quotation.gstList ? (this.quotation.gstList[key] ? this.quotation.gstList[key] : this.quotation.gstPercentage) : this.quotation.gstPercentage
      const vendor = this.quotation.comparedVendors[key]
      if (vendor.code === 'No GST') {
        return '-'
      }

      if (vendor.code === 'GST Inclusive') {
        let gstableAmount = 0
        this.quotationItems.forEach(item => {
          gstableAmount += (item.gst[key] && item.gst[key] === 'yes') ? item.quantity * (item.unitCost[key] ? item.unitCost[key] : 0) : 0
        })
        // eslint-disable-next-line no-mixed-operators
        const gstAmount = ((gstableAmount / (100 + parseFloat(gstPercentageVal))) * parseFloat(gstPercentageVal))
        return Number.isNaN(gstAmount) ? '0.00' : gstAmount.toFixed(2)
      }

      let total = 0

      this.quotationItems.forEach(item => {
        total += (item.gst[key] && item.gst[key] === 'yes') ? item.quantity * (item.unitCost[key] ? item.unitCost[key] : 0) : 0
      })

      // eslint-disable-next-line operator-assignment
      total = ((gstPercentageVal / 100) * total)

      return Number.isNaN(total) ? '0.00' : total.toFixed(2)
    },
    getGrandTotal(key) {
      return ((this.getGST(key) === '-' ? 0 : parseFloat(this.getGST(key))) + parseFloat(this.getSubTotal(key))).toFixed(2)
    },
    removeAttachment(url) {
      this.$http
        .post('purchase/purchase-request/remove-attachment', { url, purchaseRequest: this.$route.params.id })
        .then(response => {
          this.$emit('update:purchaseRequest', response.data.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveDescription() {
      const url = this.purchaseRequest.attachments[this.attachmentKey].data
      this.$http
        .post('purchase/purchase-request/save-description', { url, description: this.description, purchaseRequest: this.$route.params.id })
        .then(response => {
          this.$emit('update:purchaseRequest', response.data.data)
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    warningBeforeEdit() {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Editing the form now will <strong>reset the approval process</strong> and require all signatories to confirm and sign once again.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Edit this QCF',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'purchasing-purchase-requests-edit', params: { id: this.$route.params.id } })
          }
        })
    },
    submitForApproval() {
      let firstSwalMessage = 'You are proceeding to sign this QCF. Following this, It will be ready for Purchase Order creation by Central Purchasing'
      let secondSwalTitle = 'QCF Approved'
      let secondSwalMessage = `${this.purchaseRequest.caseID} is approved and ready for Purchase Order creation by Central Purchasing`
      if (this.quotation.signatories.length > 1) {
        firstSwalMessage = `You are proceeding to sign this QCF. The QCF will then be sent to ${this.quotation.signatories[1] ? this.quotation.signatories[1].user.name : ''} for signing.`
        secondSwalTitle = 'Submitted for Approval'
        secondSwalMessage = `${this.purchaseRequest.caseID} will now be sent to the next assigned signatory (${this.quotation.signatories[1] ? this.quotation.signatories[1].user.name : ''}) for signing.`
      }
      this.$swal({
        title: 'Submit this QCF?',
        html: firstSwalMessage,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('url', window.location.origin)
            formData.append('stringID', this.purchaseRequest.stringID)

            this.$http.post(`purchase/purchase-request/${this.$route.params.id}/quotation/${this.quotation._id}/requester/submit`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.$swal({
                  title: secondSwalTitle,
                  html: secondSwalMessage,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(confirm => {
                    if (confirm.value) {
                      this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.$route.params.id } })
                    }
                  })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    sendBackToRequester(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.sendBackToRequesterForm.validate().then(success => {
        if (success) {
          this.$refs['modal-send-back'].toggle('#toggle-btn')
          const formData = new FormData()
          formData.append('comment', this.comment)
          formData.append('stage', 'in-request')

          this.$http.post(`purchase/purchase-request/${this.$route.params.id}/send-back`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.$swal({
                title: 'QCF updated to Pending Edit',
                html: `The requester (${this.purchaseRequest.createdBy ? this.purchaseRequest.createdBy.name : ''}) has been informed of your comments. You may verify this QCF after the requester re-submits this QCF.`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/success.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then(confirm => {
                  if (confirm.value) {
                    this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.$route.params.id } })
                  }
                })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'comment') {
                    this.commentError = validationError.msg
                    this.commentValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },

    approveQCF() {
      let index = this.$route.query.type
      // eslint-disable-next-line radix
      index = parseInt(index)
      const id = this.$route.query.user
      let firstSwalMessage = ''
      let secondSwalMessage = ''
      let secondSwalTitle = 'QCF Signed'
      if ((this.quotation.signatories.length - 1) === index) {
        firstSwalMessage = 'You are proceeding to sign this QCF. Following this, Central Purchasing will start the PO process.'
        secondSwalTitle = 'QCF Approved'
        secondSwalMessage = `${this.purchaseRequest.caseID} is now ready for Purchase Order creation by Central Purchasing.`
      } else {
        firstSwalMessage = `You are proceeding to sign on this QCF. The QCF will then be sent to ${this.quotation.signatories[index + 1] ? this.quotation.signatories[index + 1].user.name : ''} for signing.`
        secondSwalMessage = `${this.purchaseRequest.caseID} will now be sent to the next assigned signatory (${this.quotation.signatories[index + 1] ? this.quotation.signatories[index + 1].user.name : ''}) for signing.`
      }
      // this.previewPage = true
      this.$swal({
        title: 'Sign this QCF?',
        html: firstSwalMessage,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('url', window.location.origin)
            formData.append('id', id)
            formData.append('index', index)
            formData.append('stringID', this.purchaseRequest.stringID)
            formData.append('approverType', this.approverType)
            formData.append('signatoryRequired', this.quotation.signatoryRequired)

            this.$http.post(`purchase/purchase-request/${this.$route.params.id}/approve-qcf/${this.quotation._id}`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.$swal({
                  title: secondSwalTitle,
                  html: secondSwalMessage,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(confirm => {
                    if (confirm.value) {
                      this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.$route.params.id } })
                    }
                  })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    setData() {
      this.purchaseRequestID = this.purchaseRequest._id
      this.status = this.purchaseRequest.status
      this.stringID = this.purchaseRequest.stringID

      this.$http.get(`directory/users/${this.purchaseRequest.createdBy._id}/show`)
        .then(response => {
          this.user = response.data ?? {}
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    submitForm() {
      this.$refs.purchaseRequestStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`purchase/purchase-request/${this.purchaseRequestID}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.purchaseRequest.status = response.data.data.status || 'confirmed'
              this.setData()
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
